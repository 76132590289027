<template>
  <v-card flat class="ma-0 pa-0">
    <v-card-text class="px-10">
      <v-row dense class="my-2">
        <v-col cols="12">
          <span class="title font-weight-bold">User Information</span>
        </v-col>
      </v-row>
      <v-form lazy-validation ref="form">
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              name="fullname"
              type="text"
              label="Full Name *"
              autocomplete="off"
              v-model="formFullName"
              dense
              data-test="fullname"
              data-cy="rfq-fullname"
              :disabled="!isQuoteIncomplete && !isAdmin"
              class="mt-1 subtitle-1"
              @input="
                updateUIState({
                  key: 'full_name',
                  value: formFullName,
                })
              "
              :rules="
                hybridRules({
                  required: true,
                  formValue: formFullName,
                  vuexValue: quoteUser ? quoteUser.full_name : null,
                })
              "
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="pa-0 ma-0">
          <v-col cols="12">
            <span class="subtitle-1 mb-0">Email:</span
            ><span class="subtitle-1 text--primary ml-2" data-test="email">{{
              formEmail
            }}</span>
          </v-col>
        </v-row>
        <v-row
          v-if="
            quoteUser &&
              quoteUser.company_request.length === 0 &&
              quoteUser.groups.length === 0
          "
        >
          <v-col cols="12" md="6">
            <EditProfileForm
              :disabled="!isQuoteIncomplete && !isAdmin"
              @saveData="onSaveForm"
              :projectUser="quoteUser"
              :companyRequest="true"
            />
          </v-col>
        </v-row>
        <div v-else>
          <div
            v-if="
              quoteUser &&
                quoteUser.company_request.length > 0 &&
                quoteUser.groups.length === 0
            "
          >
            <v-row dense class="pa-0 ma-0">
              <v-col cols="12">
                <span class="subtitle-1 mb-0">Request to join Company</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  name="company_request"
                  type="text"
                  autocomplete="off"
                  v-model="formCompanyRequest"
                  dense
                  :disabled="!isQuoteIncomplete && !isAdmin"
                  data-test="companyrequest"
                  class="subtitle-1"
                  @input="
                    updateUIState({
                      key: 'company_request',
                      value: formCompanyRequest,
                    })
                  "
                  :rules="
                    hybridRules({
                      required: true,
                      formValue: formCompanyRequest,
                      vuexValue: quoteUser ? quoteUser.company_request : null,
                    })
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "UsersView",
  props: {
    quoteUser: {
      type: Object,
      default: null,
    },
    isQuoteIncomplete: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditProfileForm: () =>
      import("@/modules/user-profile/components/EditProfileForm.vue"),
  },
  data() {
    return {
      formEmail: this.quoteUser ? this.quoteUser.email : null,
      formFullName: this.quoteUser ? this.quoteUser.full_name : null,
      formCompanyRequest: this.quoteUser
        ? this.quoteUser.company_request
        : null,
    };
  },
  methods: {
    hybridRules({ required }) {
      const rules = [];
      if (required) {
        const rule = (v) => !!v || "Input is required";
        rules.push(rule);
      }
      return rules;
    },
    onSaveForm(payload) {
      this.$emit("saveData", payload);
    },
    onSaveUserData(payload) {
      this.$emit("saveUserData", payload);
    },
    updateUIState(payload) {
      this.$store.dispatch("ui/updateQuoteUser", payload);
    },
    isUserDetailsValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>
