var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-0 pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"px-10"},[_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"title font-weight-bold"},[_vm._v("User Information")])])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mt-1 subtitle-1",attrs:{"name":"fullname","type":"text","label":"Full Name *","autocomplete":"off","dense":"","data-test":"fullname","data-cy":"rfq-fullname","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"rules":_vm.hybridRules({
                required: true,
                formValue: _vm.formFullName,
                vuexValue: _vm.quoteUser ? _vm.quoteUser.full_name : null,
              })},on:{"input":function($event){return _vm.updateUIState({
                key: 'full_name',
                value: _vm.formFullName,
              })}},model:{value:(_vm.formFullName),callback:function ($$v) {_vm.formFullName=$$v},expression:"formFullName"}})],1)],1),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1 mb-0"},[_vm._v("Email:")]),_c('span',{staticClass:"subtitle-1 text--primary ml-2",attrs:{"data-test":"email"}},[_vm._v(_vm._s(_vm.formEmail))])])],1),(
          _vm.quoteUser &&
            _vm.quoteUser.company_request.length === 0 &&
            _vm.quoteUser.groups.length === 0
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('EditProfileForm',{attrs:{"disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"projectUser":_vm.quoteUser,"companyRequest":true},on:{"saveData":_vm.onSaveForm}})],1)],1):_c('div',[(
            _vm.quoteUser &&
              _vm.quoteUser.company_request.length > 0 &&
              _vm.quoteUser.groups.length === 0
          )?_c('div',[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1 mb-0"},[_vm._v("Request to join Company")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"company_request","type":"text","autocomplete":"off","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"companyrequest","rules":_vm.hybridRules({
                    required: true,
                    formValue: _vm.formCompanyRequest,
                    vuexValue: _vm.quoteUser ? _vm.quoteUser.company_request : null,
                  })},on:{"input":function($event){return _vm.updateUIState({
                    key: 'company_request',
                    value: _vm.formCompanyRequest,
                  })}},model:{value:(_vm.formCompanyRequest),callback:function ($$v) {_vm.formCompanyRequest=$$v},expression:"formCompanyRequest"}})],1)],1)],1):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }